/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import './assets/styles/modals';
@import 'ngx-toastr/toastr';

@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import 'ngx-toastr/toastr-bs5-alert';

.btn-rounded {
    border-radius: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    font-style: normal;
    padding: 10px 25px;
    filter: drop-shadow(0px 6px 3px rgba(0, 0, 0, 0.46));
 }
 .form-control {
    &::placeholder {
        color: #ccc;
    }
}