@import "variables";

$modal-content-bg: $modal-background-color;
$modal-header-border-color:transparent;
$modal-content-border-radius:15px;
$modal-content-color:white;
$modal-inner-padding: $modal-inner-padding;
$modal-header-padding: $modal-header-inner-padding;
.custom-modal {
    .form-label {
        color:#2e4382 !important;
    }
    .modal-body-text{
        font-weight: 600;
        font-size: 18px;
        color: $modal-body-text-color !important;
    }
    .modal-footer{
        justify-content: space-evenly !important;
        padding-bottom: 1rem;
    }
}
.form-control {
    border-radius: 10em !important;
    border: 0 !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.btn-success {
    background-color: #9ED369 !important;
    color: white !important;
    padding: 1em 0.5em !important;
    border-radius: 1em !important;
    border: none !important;
    min-width: 100px;
}